<template>
      <div class="full-width full-height">
            <VueSlickCarousel @afterChange="change" id="carousel" ref="carousel" v-bind="settings">
                  <div class="item" v-for="(item, index) in items" :key="index">
                        <div class="slider-info">
                              <p>{{ 10 > index + 1 ? `${index + 1}` : index + 1 }}</p>
                              <span class="slider__title">{{ item.title }}</span>
                        </div>
                        <div class="slider-info hidden">
                              <p>&nbsp;</p>
                              <span class="slider__title">&nbsp;</span>
                        </div>
                        <img class="logo" width="100%" :class="{ 'gallery-big-image' : item.title === 'Motto' || item.title === 'Motto bloor' }" :src="item.image" />
                        <!-- <div class="slick-slide-image" :data-src="item.image"></div> -->
                  </div>
                  <template #prevArrow>
                        <figure>
                              <img src="@/assets/icons/svg/arrows/arrow-left.png" width="22px" class="filter invert-100" alt="arrow icon" draggable="false">
                              <figcaption class="ml-10">Back</figcaption>
                        </figure>
                  </template>
                  <template #nextArrow>
                        <figure>
                              <figcaption class="mr-10">Next</figcaption>
                              <img src="@/assets/icons/svg/arrows/arrow-right.png" width="22px" class="filter invert-100" alt="arrow icon" draggable="false">
                        </figure>
                  </template>
                  <template v-if="customPaging" #customPaging="page">
                        <div class="custom-dot">
                        {{ 10 > (page + 1) ? `0${page + 1}` : `${page + 1}` }}
                        </div>
                  </template>
            </VueSlickCarousel>
            <div v-if="scroller" class="sliderState">
                  <div class="slideNumbers">
                        <span>Swipe To Explore</span>
                        <span class="mr-5">{{currentIndex + 1}} / {{total}}</span>
                  </div>
                  <div class="progress-wrapper mt-15">
                        <div :style="backgroundStyles" class="linear-progress"></div>
                  </div>
            </div>

            <modal :active="showModal" @close="close()" v-if="showModal">
                  <template slot="body">
                        <div class="full-width full-height">
                              <VueSlickCarousel id="modal-slide" ref="carousel" v-bind="popUpSettings">
                                    <div class="item" v-for="(item, index) in items" :key="index">
                                          <img class="modal-slider-image" width="100%" :src="item.image" />
                                    </div>
                              </VueSlickCarousel>  
                        </div>                  
                  </template>
            </modal>

      </div>
</template>
 
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Modal from "@/components/base/modal/modal";

export default {
      name: "MyComponent",
      components: { VueSlickCarousel , Modal },
      props: {
            settings :{
                  type: Object,
                  default: ()=>{}
            },
            scroller :{
                  type: Boolean,
                  default: true
            },
            customPaging :{
                  type: Boolean,
                  default: false
            },
            items: {
                  type: Array,
                  default: ()=> {}
            }
      },
      data(){
            return {
                  showModal: false,
                  total: 0,
                  currentIndex: 0,
                  selectedItem: 0,

                  popUpSettings: {
                        arrows: true,
                        centerMode: true,
                        centerPadding: "50px",
                        dots: false,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 3,
                        responsive: [
                              {
                                    breakpoint: 900,
                                    settings: {
                                          centerPadding: "100px",
                                          slidesToShow: 2,
                                          slidesToScroll: 2,
                                    },
                              },
                              {
                                    breakpoint: 600,
                                    settings: {
                                          centerPadding: "50px",
                                          slidesToShow: 2,
                                          slidesToScroll: 2,
                                    },
                              },
                              {
                                    breakpoint: 480,
                                    settings: {
                                          centerPadding: "30px",
                                          slidesToShow: 1,
                                          slidesToScroll: 1,
                                    },
                              },
                        ],
                  },
            }
      },
      computed: {
            widthPercentage(){
                  if(this.currentIndex === 0) return 0
                  return this.currentIndex * this.total
            },
            backgroundStyles(){
                  return {
                        'width': `${(this.currentIndex + 1) * this.share}%`
                  }
            },
            share(){
                  return 100 / this.total
            }
      },
      methods: {
            handleModalShow(item){
                  this.popUpSettings.initialSlide = +(item);
                  this.selectedItem = item;

                  this.showModal = true;
            },
            close(){
                  this.showModal = false
            },
            change(e){
                  this.currentIndex = e;
            },
            addSliderImages() {
                  const images = document.getElementsByClassName('slick-slide-image');

                  for(let i = 0; i < images.length; i++){
                        images[i].style.background = " url(' "+images[i].getAttribute('data-src')+" ') ";
                  }
            }
      },
      mounted(){
            this.$nextTick(()=> {
                  // console.log('xx', this.$refs.carousel.$options._renderChildren.length)
                  this.total = this.items?.length || this.$refs.carousel.$options._renderChildren.length;
                  // const _C = document.querySelector('#carousel');
                  // console.log(_C)
            });
            
            this.addSliderImages();

            // document.querySelector('.slick-current').style.position = "relative";
            // document.querySelector('.slick-current').innerHTML += `<span class="currentTitle">${this.currentIndex + 1}</span>`
      }
};
</script>

<style lang="scss">

      .slider-info{
            display: none;
            color: #fff;
            margin-bottom: 50px;
            font-family: 'Vaud';
            position: relative;
            text-align: center;
            margin-top: 5px;
            &.hidden{
                  display: block;
                  p{
                  &::before{
                        content: "";
                        display: none;
                  }
            }
            }
            p{
                  font-size: 11px;
                  opacity: 0.4;
                  &::before{
                        content: "";
                        height: 1px;
                        width: 20px;
                        background-color: #fff;
                        display: none;
                        position: absolute;
                        top: 7px;
                        left: 17px;
                  }
            }
            h4{
                  font-size: 13px;
            }
      }

      .slick-slider {
            height: 75%;
            position: unset;
            transform: scale(1.4);
      }

      .slider__title{
            font: 15px $open-sans;
            margin-bottom: 10px;
            display: inline-block;
            font-family: 'Vaud';
      }

      .slick-slide{
            &.slick-active{
                  &.slick-center{
                        .slider-info{
                              transform: scale(1.2);
                              display: block;
                              &.hidden{
                                    display: none;
                              }
                        }
                  }
            }
      }

      .slick-list {
            padding: 0 !important;
      }

      .slick-prev, .slick-next {
            top: 104%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 103.4px;
            font-family: 'Vaud';
            height: 28px;
            @include toRem(font-size, 12);
            color: $white;

            @media screen and (max-width: 576px) {
                  display: block;

                  figcaption {
                        display: none;
                  }
            }

            &::before {
                  display: none;
            }

            &:hover {
                  color: $white !important;
            }
      }

      .sliderState {
            width: 12%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            top: 17%;
            left: -150px;

            .progress-wrapper {
                  position: relative;
                  left: 217px;
                  width: 100%;
                  z-index: 2;
                  height: 2px;
                  background: #878787;
                  opacity: 0.5;
      
                  .linear-progress {
                        position: absolute;
                        width: 10%;
                        z-index: 99999;
                        height: 2px;
                        background: #fff;
                        opacity: 1;
                  }
            }
      
            .slideNumbers {
                  position: relative;
                  left: 217px;
                  display: flex;
                  font-size:14px;
                  font-family: 'Vaud';
                  justify-content: space-between;
                  color: $white;
                  width: 100%;
                  span{
                        font-size: 13px!important;
                  }
            }
      }

      .slick-next {
            right: 215px;

            @media screen and (max-width: 1366px) {
                  right: 199px;
            }
            @media screen and (max-width: 576px) {
                  right: -2%;
                  margin: 0;
            }
      }

      .slick-prev {
            right: 317px;
            left: unset;
            z-index: 99999;

            @media screen and (max-width: 1366px) {
                  right: 289px;
            }
            @media screen and (max-width: 576px) {
                  right: 13%;
                  margin: 0;
            }
      }
      
      .slick-track:not(.slick-current){
            .slick-slide-image , .logo {
                  transform: scale(.7)
            }
      }

      .slick-current {
            .currentTitle {
                  position: absolute;
                  z-index: 99;
                  top: 0px;
            }
            .slick-slide-image , .logo {
                  transform: scale(1.2)  !important;
                  transition: transform .3s;
            }
      }

      .modal{
            height: 95%;
            width: 100%;
            margin: auto;
            background: none!important;
            box-shadow: unset!important;
            .slick-slider{
                  transform: scale(1)!important;
            }
            .modal-slider-image{
                  transform: scale(1)!important;
                  max-height: 480px;
                  width: 60%;
                  margin: auto;
            }
            .slick-prev{
                  left: 40%;
            }
            .slick-next{
                  right: 43%;
            }
            .slick-prev, .slick-next {
                  display: flex!important;
                  height: 100vh;
                  top: 20%;
                  justify-content: flex-start!important;
                  align-items: flex-end!important;
                  width: 103.4px;
                  font-family: 'Vaud';
                  @include toRem(font-size, 12);
                  color: $white;
                  &::before {
                        display: none;
                  }

                  &:hover {
                        color: $white !important;
                  }
            }
      }

      .modal-backdrop{
            backdrop-filter: blur(30px);
      }

      .close{
            top: 5%;
            width: 50px;
            height: 50px;
            float: right;
            border-radius: 19px!important;
            z-index: 999!important;
            background-color: #fff!important;
            color: #000!important;
            img{
                  filter: brightness(0.5);
            }
      }

      .slick-slide{
            .gallery-big-image{
                  max-width: 500px;
                  margin: auto;
            }
      }

      .slick-slide-image{
            background-position: center!important;
            background-repeat: no-repeat!important;
            background-size: cover!important;
            height: 430px;
            width: 100%;
      }

      @media(max-height: 750px){
            .modal{
                  .slick-prev, .slick-next {
                        top: 21%;
                  }
            }
      }

      @media screen and (min-width: 1500px) {
            .slider-info{
                  margin-top: 35px;
            }
      }

      @media screen and (max-width: 1600px) {
            .slick-slide{
                  .gallery-big-image{
                        max-width: 300px;
                        margin: auto;
                  }
            }
      }

      @media screen and (min-width: 1920px) {
            .slick-next{
                  right: 12%;
            }
            .slick-prev{
                  right: 18%;
            }
      }

      @media screen and (min-height: 840px) {
            .slick-slide-image{
                  height: 345px;
            }
      }

</style>