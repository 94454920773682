<template>
      <div class="gallery">
            <Carousel v-if="items.length" :items="items" :settings="settings"/>
      </div>
</template>

<script>
import Carousel from "@b/carousel/Carousel";
import {getGalleryInfo} from "@/services/axios/gallery.service";
      export default {
            name: 'gallery',
            components: { Carousel },
            data(){
                  return {
                        settings: {
                              arrows: true,
                              centerMode: true,
                              centerPadding: "50px",
                              dots: false,
                              infinite: true,
                              speed: 500,
                              slidesToShow: 3,
                              slidesToScroll: 3,
                              initialSlide: 0,
                              responsive: [
                                    {
                                          breakpoint: 900,
                                          settings: {
                                                centerPadding: "100px",
                                                slidesToShow: 2,
                                                slidesToScroll: 2,
                                          },
                                    },
                                    {
                                          breakpoint: 600,
                                          settings: {
                                                centerPadding: "50px",
                                                slidesToShow: 2,
                                                slidesToScroll: 2,
                                          },
                                    },
                                    {
                                          breakpoint: 480,
                                          settings: {
                                                centerPadding: "30px",
                                                slidesToShow: 1,
                                                slidesToScroll: 1,
                                          },
                                    },
                              ],
                        },
                        items: []
                  }
            },
            methods: {
                  getGalleryInfo(){
                        getGalleryInfo().then(res => {
                              this.items = res.data.data
                              this.items.reverse();
                        }).catch(e => {
                              console.log(e)
                        })
                  }
            },
            mounted(){
                  this.getGalleryInfo()
            }
      }
</script>

<style lang='scss'>
      .gallery {
            border-top: 1px solid $mainBorderColor;
            padding-top: 100px;
            height: 85vh;

            @media screen and (max-width: 1366px){
                  padding-top: 100px;
            }
            @media screen and (max-width: 576px){
                  height: 100%;
                  overflow-x: hidden;
            }
            // img {
            //       aspect-ratio: 1280 / 720;
            // }
      }
</style>